
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { QuestionFilled, ArrowLeftBold } from '@element-plus/icons';
import { DEFAULT_PAGE_SIZE, ShowEventUserStatisticsType, RankPrizeType, RankPrize, PrizeStatus, EventStatistics } from '@/services/api';
import { useEventUserStatistics, useEventStatistics } from '@/composables/api/wte';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { isNil } from 'lodash';
import { getThousandSeparator } from '@/utils/render';
import { exportEventStatistics, exportEventUserStatistics } from '@/services/api/wte';
import dayjs from 'dayjs';
import download from 'downloadjs';
import { usePage } from '@/composables/page';

type PrizeMap = {[eventId: number]: RankPrize[]};

export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const searchMember = ref();
    const detailPage = ref(1);
    const detailPageSize = ref(DEFAULT_PAGE_SIZE);
    const dateRange = ref(null);
    const startDate = ref('');
    const endDate = ref('');
    const searchEventId = ref<string>();
    const selectedEventId = ref<number>();
    const selectedEventStatisticsIdx = ref<number>();
    const showEventUserStatisticsType = ref<ShowEventUserStatisticsType>();
    const rankZH = ['一', '二', '三'];
    const productPrizes = ref<PrizeMap>({});
    const contentPrizes = ref<PrizeMap>({});
    const actorPrizes = ref<PrizeMap>({});
    const detailProductPrizes = ref<PrizeMap>({});
    const detailContentPrizes = ref<PrizeMap>({});
    const detailActorPrizes = ref<PrizeMap>({});

    const router = useRouter();
    const route = useRoute();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const queryEventStatistics = useEventStatistics({ page, pageSize, startDate, endDate, searchEventId });
    const {
      data: eventStatisticsRes,
      isLoading: isLoadingEventStatistics,
      isFetching: isFetchingEventStatistics
    } = queryEventStatistics;

    const enabledUseEventUserStatistics = computed(() => !!selectedEventId.value);
    const queryEventUserStatisticsRes = useEventUserStatistics({
      eventId: selectedEventId,
      userType: showEventUserStatisticsType,
      memberNo: searchMember,
      page: detailPage,
      pageSize: detailPageSize
    }, enabledUseEventUserStatistics);
    const {
      data: eventUserStatisticsRes,
      isLoading: isLoadingEventUserStatistics,
      isFetching: isFetchingEventUserStatistics
    } = queryEventUserStatisticsRes;

    watch(eventStatisticsRes, () => {
      if (!eventStatisticsRes.value?.data) return;

      productPrizes.value = eventStatisticsRes.value.data.reduce((pre, curr) => {
        pre[curr.eventId] = curr.rankPrizes.filter(item => item.type === RankPrizeType.PRODUCT);
        return pre;
      }, {});

      contentPrizes.value = eventStatisticsRes.value.data.reduce((pre, curr) => {
        pre[curr.eventId] = curr.rankPrizes.filter(item => item.type === RankPrizeType.CONTENT);
        return pre;
      }, {});

      actorPrizes.value = eventStatisticsRes.value.data.reduce((pre, curr) => {
        pre[curr.eventId] = curr.rankPrizes.filter(item => item.type === RankPrizeType.ACTOR);
        return pre;
      }, {});
    });

    watch(eventUserStatisticsRes, () => {
      if (!eventUserStatisticsRes.value?.data) return;

      detailProductPrizes.value = eventUserStatisticsRes.value.data.reduce((pre, curr) => {
        pre[curr.memberNo] = curr.rankPrizes && curr.rankPrizes.filter(item => item.type === RankPrizeType.PRODUCT).reduce((_pre, _curr) => {
          _pre[`${_curr.memberNo}-${_curr.rank}`] = _curr;
          return _pre;
        }, {});
        return pre;
      }, {});

      detailContentPrizes.value = eventUserStatisticsRes.value.data.reduce((pre, curr) => {
        pre[curr.memberNo] = curr.rankPrizes && curr.rankPrizes.filter(item => item.type === RankPrizeType.CONTENT).reduce((_pre, _curr) => {
          _pre[`${_curr.memberNo}-${_curr.rank}`] = _curr;
          return _pre;
        }, {});
        return pre;
      }, {});

      detailActorPrizes.value = eventUserStatisticsRes.value.data.reduce((pre, curr) => {
        pre[curr.memberNo] = curr.rankPrizes && curr.rankPrizes.filter(item => item.type === RankPrizeType.ACTOR).reduce((_pre, _curr) => {
          _pre[`${_curr.memberNo}-${_curr.rank}`] = _curr;
          return _pre;
        }, {});
        return pre;
      }, {});
    });

    const handleKeyword = (_keyword: string) => {
      searchEventId.value = _keyword;
    };

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }

      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    const handleExcelExport = async() => {
      let data;
      if (isNil(selectedEventId)) {
        data = await exportEventStatistics({
          query: {
            startDate: startDate.value,
            endDate: endDate.value,
            keyword: searchEventId.value
          }
        });
      } else {
        data = await exportEventUserStatistics({
          query: {
            userType: showEventUserStatisticsType.value,
            keyword: searchEventId.value,
            memberNo: searchMember.value
          }
        });
      }

      const currentDate = dayjs().format('YYYY-MM-DD');
      download(data, `${currentDate}活動數據.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    };

    const handleShowUserStatistics = (id?: number, idx?: number, type?: ShowEventUserStatisticsType) => {
      if (isNil(id)) {
        selectedEventId.value = undefined;
        selectedEventStatisticsIdx.value = undefined;
        showEventUserStatisticsType.value = undefined;
      } else {
        detailPage.value = 1;
        selectedEventId.value = id;
        if (isNil(selectedEventStatisticsIdx.value)) {
          selectedEventStatisticsIdx.value = idx;
        }
        showEventUserStatisticsType.value = type;
        detailPageSize.value = DEFAULT_PAGE_SIZE;
      }
    };

    const getPrizeInfo = (rankPrizeType: RankPrizeType, rank: number, eventId: number, isDetail = false, memberNo = '') => {
      try {
        let prizeInfo: PrizeMap;
        switch (rankPrizeType) {
          case RankPrizeType.PRODUCT:
            if (isDetail) prizeInfo = detailProductPrizes.value;
            else prizeInfo = productPrizes.value;
            break;
          case RankPrizeType.CONTENT:
            if (isDetail) prizeInfo = detailContentPrizes.value;
            else prizeInfo = contentPrizes.value;
            break;
          case RankPrizeType.ACTOR:
            if (isDetail) prizeInfo = detailActorPrizes.value;
            else prizeInfo = actorPrizes.value;
            break;
        }
        if (isDetail) {
          return prizeInfo[memberNo][`${memberNo}-${rank}`];
        }
        return prizeInfo[eventId][rank];
      } catch (err) {
        return null;
      }
    };

    const handleRankPrize = (rowIndex: number, rank: number, userType: ShowEventUserStatisticsType, rankPrizeType: RankPrizeType) => {
      selectedEventId.value = eventStatisticsRes.value.data[rowIndex].eventId;
      selectedEventStatisticsIdx.value = rowIndex;
      const prizeInfo: RankPrize = getPrizeInfo(rankPrizeType, rank, selectedEventId.value, false);
      if (prizeInfo.isEligible) {
        detailPage.value = 1;
        showEventUserStatisticsType.value = userType;
        detailPageSize.value = DEFAULT_PAGE_SIZE;
        searchMember.value = prizeInfo.memberNo;
      }
    };

    const getPrizeDisplay = (type: RankPrizeType, rank: number, eventId: number, isDetail = false, memberNo = '') => {
      const prizeInfo = getPrizeInfo(type, rank, eventId, isDetail, memberNo);
      return isNil(prizeInfo) ? '-' : `${prizeInfo.prize}%`;
    };

    const pipePrizeStatus = (prizeStatus: PrizeStatus) => {
      switch (prizeStatus) {
        case PrizeStatus.PAID:
          return '已轉帳';
        case PrizeStatus.PROCESSING:
          return '系統處理中';
        case PrizeStatus.INVALID:
          return '未符合資格';
        case PrizeStatus.VALID:
          return '尚未提領';
        default:
          return '-';
      }
    };

    // cache搜索條件在querystring
    watch([startDate, endDate, searchEventId], ([startDate, endDate, searchEventId]) => {
      router.push({ query: { ...route.query, searchEventId, startDate, endDate } });
    });
    // 從querystring帶入搜索條件
    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate as string;
        endDate.value = query.endDate as string;
        searchEventId.value = query.searchEventId as string;
        // 避免[null,null], datePicker會顯示當天日期
        if (startDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      queryEventStatistics.remove.value();
      queryEventUserStatisticsRes.remove.value();
    });

    const getPrizesTextColor = (row: EventStatistics, type: RankPrizeType, rank: number, eventId: number) => {
      const textData = {
        color: '',
        isLink: ''
      };
      try {
        if (row.paymentAmount === null || row.paymentAmount === undefined) {
          textData.color = '';
          textData.isLink = 'none';
          return textData;
        }
        if (getPrizeInfo(type, rank, eventId).isEligible) {
          textData.color = '#3f9cfb';
          textData.isLink = '';
          return textData;
        } else {
          textData.color = '#d7d7d7';
          textData.isLink = 'none';
          return textData;
        }
      } catch (error) {
        return null;
      }
    };

    return {
      isNil,
      page,
      rankZH,
      RankPrizeType,
      selectedEventDataId: selectedEventId,
      selectedEventDataIdx: selectedEventStatisticsIdx,
      dateRange,
      eventDataRes: eventStatisticsRes,
      isLoadingEventData: isLoadingEventStatistics,
      isFetchingEventData: isFetchingEventStatistics,
      eventDataDetailRes: eventUserStatisticsRes,
      isLoadingEventDataDetail: isLoadingEventUserStatistics,
      isFetchingEventDataDetail: isFetchingEventUserStatistics,
      showDetailType: showEventUserStatisticsType,
      detailPage,
      searchEventId,
      QuestionFilled,
      ArrowLeftBold,
      formatLocalTime,
      handleKeyword,
      handleDateRangeChange,
      handleExcelExport,
      handleShowUserStatistics,
      handleRankPrize,
      getPrizeDisplay,
      getThousandSeparator,
      getPrizeInfo,
      pipePrizeStatus,
      getPrizesTextColor,
      setPageQuery
    };
  }
});
